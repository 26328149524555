<template>
    <div>
        <x-header>
            <img slot="headerImage" src="../../../assets/guojiqiyun/banner.jpg" alt="">
            <h1 slot="headerTitle">{{$store.state.navShow == true ? '国际汽运—俄集装箱公路运输领跑者' : 'International land transport - the leader of container road transport in Russia'}}</h1>
        </x-header>
        <div class="internationCar">
            <div class="titleMain">
              <p>{{$store.state.navShow == true ? 'MG集团在俄罗斯集装箱公路运输领域占有重要地位，拥有强大的海关资源和运输团队，货运车队配备有260余辆皮卡厢式货车、400余辆配送车，覆盖俄罗斯莫斯科全境。配有物流货物追踪系统，实时查询运踪，按门到户进行配送。' : 'Mg group plays an important role in the field of container road transportation in Russia, with strong customs resources and transportation team. The freight fleet is equipped with more than 800 pickup trucks and distribution vehicles, covering the whole territory of Moscow, Russia. And it is equipped with logistics cargo tracking system, real-time query of transportation trace, door-to-door distribution.'}}</p>
              <div class="main">
                <div class="first_main publicCss" :style="$store.state.navShow == true ? 'width: 1021px;': 'width: 1421px;'">
                    <div class="first_main_L" data-aos="fade-left">
                      <img src="../../../assets/guojiqiyun/iconCenter1.png" alt="">
                      <p>{{$store.state.navShow == true ? '时效快速稳定' : 'Fast and stable aging'}}</p>
                    </div>
                    <div class="first_main_R" data-aos="fade-right">
                        <p>{{$store.state.navShow == true ? '全程GPS定位' : 'Full GPS positioning'}}</p>
                        <img src="../../../assets/guojiqiyun/iconCenter2.png" alt="">
                    </div>
                </div>
                <div class="second_main publicCss" :style="$store.state.navShow == true ? 'width: 883px;': 'width: 1283px'">
                    <div class="second_main_L" data-aos="fade-left" :data-aos-delay="200">
                      <img src="../../../assets/guojiqiyun/iconCenter3.png" alt="">
                      <p>{{$store.state.navShow == true ? '高效安全清关' : 'Efficient and safe customs clearance'}}</p>
                    </div>
                    <div class="second_main_R" data-aos="fade-right" :data-aos-delay="200">
                        <p>{{$store.state.navShow == true ? '配送班期灵活' : 'Flexible delivery schedule'}}</p>
                        <img src="../../../assets/guojiqiyun/iconCenter4.png" alt="">
                    </div>
                </div>
                <div class="carCenterImgDiv">
                    <img class="carCenterImg" src="../../../assets/guojiqiyun/carCenter.png" alt="">
                    <div class="car_word" :style="$store.state.navShow == true ? '' : 'left: 469px;'">
                      <p>{{$store.state.navShow == true ? '卡航路线' : 'Truck Transport Route'}}</p>
                      <span>{{$store.state.navShow == true ? '广东/上海 - 莫斯科 / 明斯克' : 'Guangdong/Shanghai - Moscow/Minsk'}}</span>
                    </div>
                </div>
              </div>
            </div>
            <!-- 底图 -->
            <div class="showRelateImg">
              <img class="bottomBanner" src="../../../assets/guojiqiyun/bottomBanner.png" alt="">
              <img src="../../../assets/pages_bottom_img.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  }

}
</script>
<style lang="less" scoped>
  .internationCar{
    width: 100%;
    height: 910px;
    // background: pink;
    overflow: hidden;
    position: relative;
    margin-top: 110px;
    & .titleMain{
      width: var(--width1200px);
      // height: 500px;
      // background: orange;
      margin:0px auto 0;
      & > p{
        font-family: Microsoft YaHei;
        color: #666666;
        text-align:left ;
        text-indent: 32px;
        line-height: 32px;
      }
      & .main{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: -5px;
        & .publicCss{
          height: 100px;
          border-radius: 50px;
           display: flex;
           & > div{
              flex: 1;
              display: flex;
              align-items: center;
              & > p{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
              }
              & > img{
                margin: 5px 7px 0 7px;
              }
          }
        }
        .first_main{
          // width: 1021px;
          margin: 165px 0 30px 0;
          background: linear-gradient(-90deg, #42BD73, #3676C9, #42BE72);

          .first_main_R{
            justify-content: flex-end;
          }

        }

        .second_main{
            // width: 883px;
            background: linear-gradient(-90deg, #3676C9, #42BD73, #3677C8);
          .second_main_R{
            justify-content: flex-end;
          }
        }
        // 中间汽车图片
        .carCenterImgDiv{
          width: 100%;
          position:absolute;
          top: 3.7vw;
          left: 0;
          .carCenterImg{

          }
          .car_word{
            position: absolute;
            top: 70px;
            left: 513px;
            // background: red;
            & > p{
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #FFFFFF;
              margin-bottom: 8px;
            }
            & > span{
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
        }

      }

    }
     & .showRelateImg{
        & .bottomBanner{
          position: absolute;
          bottom: 3.6vw;
          left: 0;
          z-index: -1;
        }
        & > img:last-child{
          position: absolute;
          bottom: 0px;
          left: 0;
        }
      }
  }
</style>
